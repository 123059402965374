@import "../css/common.css"
// @import "../css/jquery.fancybox.css"

@import "_vars.sss"
@import "_base.sss"
// @import "_form-elements.sss"
@import "_text-styles.sss"
@import "btns.sss"

@import "header.sss"
@import "current.sss"
@import "faq.sss"

// @import "base-form.sss"
// @import "my-select.sss"

#content
	margin: 50px 0 0

.guide
	margin: 40px 0 0
	text-align: center

	&__link
		color: var(--main-color)
		border-bottom: 1px solid currentColor
		font-size: 18px

@media (width <= 660px)
	#content
		margin: 20px 0 0