:root
	--default-font: "Montserat", Arial, sans-serif

	--base-width: 1000px

	--body-bg-color: #F8F8F9
	--body-text-color: #333

	--base-font-size: 16px

	--main-color: #2CAC6E

	--second-color: #F4F4F4
	--thrid-color: #202730
	
	--border-color: #E5F0F9

	--default-shadow: 0px 8px 34px rgba(162, 210, 255, 0.08)

	--inline-padding: 40px

	--input-hor-spacing: 16px
	--input-vert-spacing: 24px