.head
	padding: 20px 0
	background: #ffffff
	box-shadow: var(--default-shadow)

	&__wrap
		display: flex
		align-items: center
		justify-content: space-between

	&__logo
		font-size: em(20)

	&__support

		a
			display: flex
			align-items: center
			justify-content: flex-end
			white-space: nowrap

			&:before
				content: ""
				size: 22px 26px
				margin-right: 10px
				background: url('../img/ico-wa.png') no-repeat
				background-size: contain

		&-notif
			font-size: 12px
			text-align: right
			color: red

@media (width <= 660px)
	.head
		padding: 15px 0
		font-size: 14px