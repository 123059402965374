.faq
	margin: 50px 0 0

	&__title
		text-align: center
		font-size: em(18)

	&__list
		margin: 25px 0 0

		&-item

			&:not(:first-child)
				margin: 20px 0 0

.f-item

	&__title
		cursor: pointer
		background: #ffffff
		padding: 10px 20px
		display: flex
		align-items: center
		justify-content: space-between
		border: 1px solid var(--border-color)
		font-weight: bold

		&.js__opened

			&::after
				transform: rotateZ(-45deg)

		&:after
			content: ""
			size: 10px
			border-top: 1px solid var(--main-color)
			border-right: 1px solid var(--main-color)
			transform: rotateZ(135deg)

	&__body
		display: none

		&-text
			padding: 10px 20px


@media (width <= 660px)
	.faq
		margin: 30px 0 0

		&__title
			font-size: 17px

		&__list
			margin: 15px 0 0

	.f-item

		&__body

			&-text
				padding-inline: 0
				font-size: 14px