.current
	text-align: center

	&__title
		font-size: em(18)

	&__subtitle
		color: #ababab

	&__warning
		display: inline-block
		color: red
		max-width: 300px
		text-transform: uppercase
		margin: 20px 0 0
		font-size: 14px
		font-weight: bold

	&__download
		margin: 20px 0 0


@media (width <= 660px)
	.current

		&__title
			font-size: 16px

		&__subtitle
			font-size: 14px